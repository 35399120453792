<template>
  <div>
      <div style="width:100%; height:400px;">
          <chart-block :option="option"></chart-block>
      </div>
  </div>
</template>
<script>
import ChartBlock from '../common/echart'
export default {
name: 'xxxxx',
components: {
  ChartBlock
},
data() {
  return {      
    option: {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ['新工/医/农/文科建设','教学内容和课程体系改革','师资培训','实践条件和实践基地建设','创新创业教育改革','创业联合基金'],
      axisLabel: {
                    interval: 0,
                    // rotate: 20, // 20度角倾斜显示(***这里是关键)
                    textStyle: {
                        color: '#00c5d7'
                    },
                    formatter: function(value) {
                        // console.log(value);
                        var valueDetal = value.split("-").join("");
                        console.log(valueDetal);
                        var ret = ""; //拼接加\n返回的类目项  
                        var maxLength = 6; //每项显示文字个数  
                        var valLength = valueDetal.length; //X轴类目项的文字个数
                        // console.log(valLength);
                        var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数 
                        if (rowN > 1) { //如果类目项的文字大于3,
                            for (var i = 0; i < rowN; i++) {
                                var temp = ""; //每次截取的字符串  
                                var start = i * maxLength; //开始截取的位置  
                                var end = start + maxLength; //结束截取的位置  
                         //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧  
                                temp = valueDetal.substring(start, end) + "\n";
                                ret += temp; //凭借最终的字符串  
                            }
                            return ret;
                        } else {
                            return valueDetal;




    }
  
  }
}}
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
  {
      name: '2018年第二批',
      type: 'bar',
      // stack: 'Ad',
      emphasis: {
        focus: 'series'
      },
      data: [2, 2, 13, 1, 4, 6,]
    },
    {
      name: '2019年第二批',
      type: 'bar',
      stack: 'Ad',
      emphasis: {
        focus: 'series'
      },
      data: [2, 3, 4, 8, 12, 13,]
    },
    {
      name: '2019年第一批',
      type: 'bar',
      stack: 'Ad',
      emphasis: {
        focus: 'series'
      },
      data: [14,1,13,15,2,13]
    },
   
    {
      name: '2020年第一批',
      type: 'bar',
      emphasis: {
        focus: 'series'
      },
      data: [7, 33, 4, 3,5,23]
    },
    // {
    //   name: 'Baidu',
    //   type: 'bar',
    //   barWidth: 5,
    //   stack: 'Search Engine',
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: [620, 732, 701, 734, 1090, 1130, 1120]
    // },
    // {
    //   name: 'Google',
    //   type: 'bar',
    //   stack: 'Search Engine',
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: [120, 132, 101, 134, 290, 230, 220]
    // },
    // {
    //   name: 'Bing',
    //   type: 'bar',
    //   stack: 'Search Engine',
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: [60, 72, 71, 74, 190, 130, 110]
    // },
    // {
    //   name: 'Others',
    //   type: 'bar',
    //   stack: 'Search Engine',
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: [62, 82, 91, 84, 109, 110, 120]
    // }
  ]
    }
};
  }

}
</script>
