<template>
  <div class="index">
    <div class="index-bg">
      <div class="index-box">
        <div class="container box-conter">
          <el-row>
            <el-col :span="6">
              <div class="line no-bor">
                <p class="title">项目实施进入</p>
                <p class="detail">
                  第<span> <NumberCounter :value="10"></NumberCounter></span>年
                </p>
              </div></el-col
            >
            <el-col :span="6">
              <div class="line">
                <p class="title">累计支持本科高校</p>
                <p class="detail">
                  <span> <NumberCounter :value="1000"></NumberCounter>+</span>
                </p>
              </div></el-col
            >
            <el-col :span="6">
              <div class="line">
                <p class="title">累计支持企业</p>
                <p class="detail">
                  <span> <NumberCounter :value="2000"></NumberCounter>+</span>
                </p>
              </div></el-col
            >
            <el-col :span="6">
              <div class="line">
                <p class="title">累计立项数量</p>
                <p class="detail">
                  <span> <NumberCounter :value="2.3"></NumberCounter>+</span
                  >万项
                </p>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>

    <div class="container">
      <el-divider>优秀项目案例</el-divider>
      <chart></chart>
      <!-- <div class="box-top"><span class="card_line">|</span> <span class="card_title">直播</span> <span class="card_more">查看更多》</span></div> -->
      <!-- <div class="card">
        <div></div>
        <el-row>
          <el-col
            :span="6"
            v-for="(o, index) in 4"
            :key="index"
            offset="0"
            style="padding:0 20px"
          >
            <el-card :body-style="{ padding: '0px' }" shadow="hover">
              <img
                src="https://wechatapppro-1252524126.cdn.xiaoeknow.com/appHyU6rXoy6209/image/b_u_5e6eda8715de9_S6oFBehK/l7o67fe30i3c.jpg"
                class="image"
              />
              <div style="padding: 14px">
                <span class="title">2022年教育部产学合作协同育人项目系列线上对接会：华为线上专场宣讲会</span>
                <div class="bottom clearfix">
            
                  <el-button type="text" class="button">点击观看</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div> -->
    </div>
  </div>
</template>
  
  <script>
import chart from "@/components/chart.vue";
import NumberCounter from "@/components/NumberCounter.vue";

export default {
  name: "index",
  props: {},
  data() {
    return {
      currentDate: new Date(),
    };
  },
  components: {
    chart,
    NumberCounter,
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
.container {
  margin: 0 auto;
}
.index {
  .el-divider__text {
    font-size: 30px !important;
  }
  .el-divider {
    background-color: #2fa5f7;
    margin-top: 50px;
  }
  .index-bg {
    width: 100%;
    background: url(../assets/index-banner2.jpg) no-repeat;
    height: 540px;
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: -20px;
    position: relative;
    .index-box {
      position: absolute;
      bottom: 40px;
      margin: 0 auto;
      color: #fff;
      width: 100%;
      .box-conter {
        overflow: hidden;
        margin: 0 auto;
      }
      .line {
        border-left: 1px solid #fff;
        float: left;
        // padding: 0 48px;
        width:100%;
      }
      .no-bor {
        border-left: none;
      }

      .title {
        display: block;
        font-size: 24px;
        color: #fff;
        font-weight: normal;
        margin-bottom: 15px;
      }
      .detail {
        font-size: 16px;
        span {
          font-size: 43px;
          color: #01f5f6;
          font-weight: 600;
          padding: 0 6px;
        }
      }
    }
  }
}
.box-top {
  text-align: left;
  .card_line {
    width: 3px;
    background: red;
    margin-right: 20px;
    color: red;
  }
  .card_title {
    font-size: 20px;
    font-weight: bold;
  }
  .card_more {
    font-size: 12px;
  }
}

.card {
  .title {
    text-align: left;
    font-size: 14px;
    display: inline-block;
  }
  .time {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both;
  }
}
// @media (max-width: 768px) {

//    .index ::v-deep  .el-divider__text {
//       font-size: 14px !important;
//     }
//     .index .index-bg .index-box .title{
//         font-size:16px;
//     }
 
// }

// @media (max-width: 992px) {
//   .index ::v-deep  .el-divider__text {
//       font-size: 14px!important;
//     }
//     .index .index-bg .index-box .title{
//         font-size:16px;
//     }
  
// }

@media (max-width: 1200px) {
    
//    .index ::v-deep .index {
//     .el-divider__text {
//       font-size: 16px!important;
//     }
//   }
  .index .index-bg .index-box .title{
        font-size:16px;
    }
    .index .el-divider__text{
        font-size: 16px!important;
    }
    .index .index-bg .index-box .detail span
   {
    font-size:14px;
   }
   .index-box .el-col-6{
    width:50%;
    height: 72px;
   }
   .el-row .el-col:nth-child(3) .line{
    border-left:none;
   }
}
</style>

  