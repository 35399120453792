<template>
  <div class="projects container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="'./index'">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优秀项目案例</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="box">
      <p class="title">检索</p>
      <el-divider></el-divider>
      <div class="list">
        <span>项目类型：</span>
        <ul style="color: #2c3e50">
          <li
            v-for="(item, index) in typeAry"
            :key="index"
            @click="tyepFun(index, item)"
            class="hover"
            :class="styleType == index ? 'addBor' : ''"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="list list-min">
        <span>项目批次：</span>
        <ul style="color: #2c3e50">
          <li
            v-for="(item, index) in numAry"
            :key="index"
            class="hover"
            :class="styleNum == index ? 'addBor' : ''"
            @click="numFun(index, item)"
          >
            {{ item }}
          </li>
        </ul>
        <span style="margin-top: 10px">高校名称：</span>
        <ul style="margin-top: 10px; color: #2c3e50">
          <li
            v-for="(item, index) in schoolAry"
            :key="index"
            class="hover"
            :class="styleSchool == index ? 'add-bor' : 'bor'"
            @click="schoolTitleFun(index, item.name)"
          >
            {{ item.name }}
          </li>
        </ul>
        <!-- <ul class="min-hei">
          <li v-for="(item, index) in schoolAry[0].school" :key="index">
            {{ item }}
          </li>
        </ul> -->
        <el-row :gutter="20" class="ul">
          <el-col
            :span="5"
            v-for="(item, index) in schoolData"
            :key="index"
            class="li hover"
          >
            <span @click="schoolFun(item)">{{ item }}</span></el-col
          >
        </el-row>
      </div>
      <div class="list list-min">
        <span style="margin-top: 10px">企业名称：</span>
        <ul style="margin-top: 10px; color: #2c3e50">
          <li
            v-for="(item, index) in qyAry"
            :key="index"
            class="hover"
            :class="styleName == index ? 'add-bor' : 'bor'"
            @click="schoolNameFun(index, item.name)"
          >
            {{ item.name }}
          </li>
        </ul>
        <!-- <ul class="min-hei">
          <li v-for="(item, index) in qyAry[0].value" :key="index">
            {{ item }}
          </li>
        </ul> -->

        <el-row :gutter="20" class="ul">
          <el-col
            :span="6"
            v-for="(item, index) in qyData"
            :key="index"
            class="li hover"
          >
            <span @click="nameFun(item)">{{ item }}</span></el-col
          >
        </el-row>
      </div>
      <div class="list list-min">
        <!-- <span>项目批次：</span>
        <ul>
          <li><el-link>全部</el-link></li>
          <li><el-link>2020年第一批</el-link></li>
          <li><el-link>2019年第二批</el-link></li>
          <li><el-link>2019年第一批</el-link></li>
          <li><el-link>2018年第二批</el-link></li>
        </ul> -->
        <span style="margin-top: 10px">项目负责人：</span>
        <ul style="margin-top: 10px; color: #2c3e50">
          <li
            v-for="(item, index) in nameAry"
            :key="index"
            class="hover"
            :class="styleJob == index ? 'add-bor' : 'bor'"
            @click="schoolJobFun(index, item.name)"
          >
            {{ item.name }}
          </li>
        </ul>

        <el-row :gutter="20" class="ul">
          <el-col
            :span="2"
            v-for="(item, index) in namData"
            :key="index"
            class="li hover"
          >
            <span @click="jobFun(item)">{{ item }}</span></el-col
          >
        </el-row>
      </div>
      <div class="list list-bor">
        <span style="height: 28px; line-height: 28px">搜 索：</span>
        <el-input
          placeholder="请输入内容"
          v-model="searchD"
          style="width: 200px; margin-right: 10px"
          size="mini"
          clearable
        >
        </el-input>
        <el-button type="primary" size="mini" @click="searchFun">搜索</el-button>
      </div>
    </div>
    <div class="box_card">
      <div class="title">
        <span>项目列表</span>
        <el-tag
            v-if="tagsV"
          closable
    
          size="mini"
          style="margin-left: 20px"
          @close="closeFun"
        >
          搜索：{{ tags.name }}
        </el-tag>
      </div>
      <el-divider></el-divider>
      <el-row v-if="cardData.length!=0">
        <el-col
          :span="8"
          v-for="(item, index) in cardData"
          :key="index"
          style="padding: 10px;"
        >
          <el-card shadow="hover">
            <p class="body_title">{{ item.title }}</p>
            <div>
              <p v-for="(y, i) in item.list" :key="i" class="body_list">
                {{ y.title }}<span :style="tags.name == y.value?'background:#ffffca;color:red':''">{{ y.value }}</span>
              </p>
            </div>
          </el-card></el-col
        >
      </el-row>
      <div v-else style="text-align:center;padding:30px;font-size:14px;">暂无数据</div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "",
  props: {},
  data() {
    return {
      tags: {},
      tagsV: false,
      styleJob: 0,
      styleName: "0",
      styleSchool: "0",
      styleNum: "0",
      styleType: "0",
      type: "全部",
      num: "全部",
      school: "",
      schoolD: "",
      name: "",
      nameD: "",
      job: "",
      jobD: "",
      searchD: "",

      numAry: [
        "全部",
        "2020年第一批",
        "2019年第二批",
        "2019年第一批",
        "2018年第二批",
      ],
      typeAry: [
        "全部",
        "新工科、新医科、新农科、新文科建设",
        "教学内容和课程体系改革",
        "师资培训",
        "实践条件和实践基地建设",
        "创新创业教育改革",
        "创新创业联合基金",
      ],
      cardAry: [
        {
          title: "基于VR辅助创新思维方法的建筑设计课程开发研究",
          list: [
            { title: "项目类型：", value: "教学内容和课程体系改革" },
            { title: "项目批次：", value: "2018年第二批" },
            { title: "高校名称：", value: "北京交通大学" },
            { title: "企业名称：", value: "光辉城市（重庆）科技有限公司" },
            { title: "项目负责人：", value: "胡映东" },
          ],
        },
        {
          title: "安徽中医药大学新安课栈建设",
          list: [
            { title: "项目类型：", value: "实践条件和实践基地建设" },
            { title: "项目批次：", value: "2018年第二批" },
            { title: "高校名称：", value: "安徽中医药大学" },
            { title: "企业名称：", value: "上海卓越睿新数码科技股份有限公司" },
            { title: "项目负责人：", value: "董妍妍" },
          ],
        },
        {
          title: "Intel FPGA课程开发",
          list: [
            { title: "项目类型：", value: "教学内容和课程体系改革" },
            { title: "项目批次：", value: "2019年第一批" },
            { title: "高校名称：", value: "北京化工大学" },
            { title: "企业名称：", value: "北京海云捷迅科技有限公司" },
            { title: "项目负责人：", value: "何宾" },
          ],
        },
        {
          title: "基于HCNA-IoT的物联网综合实训课程建设",
          list: [
            { title: "项目类型：", value: "教学内容和课程体系改革" },
            { title: "项目批次：", value: "2018年第二批" },
            { title: "高校名称：", value: "北京工业大学" },
            { title: "企业名称：", value: "华为技术有限公司" },
            { title: "项目负责人：", value: "方娟" },
          ],
        },
        {
          title: "基于新工科的信息与通信工程大类专业的人才培养改革研究",
          list: [
            {
              title: "项目类型：",
              value: "新工科、新医科、新农科、新文科建设",
            },
            { title: "项目批次：", value: "2018年第二批" },
            { title: "高校名称：", value: "电子科技大学" },
            { title: "企业名称：", value: "四川华迪信息技术有限公司" },
            { title: "项目负责人：", value: "何翔" },
          ],
        },
        {
          title: "新工科背景下的数字化专业人才培养新模式",
          list: [
            {
              title: "项目类型：",
              value: "新工科、新医科、新农科、新文科建设",
            },
            { title: "项目批次：", value: "2018年第二批" },
            { title: "高校名称：", value: "广州大学" },
            { title: "企业名称：", value: "新华三技术有限公司" },
            { title: "项目负责人：", value: "温武" },
          ],
        },
      ],
      cardData: [],
      schoolAry: [
        {
          name: "全部",
          school: [
            "安徽中医药大学",
            "北京交通大学",
            "北京化工大学",
            "北京工业大学",
            // "北京师范大学",
            // "北京林业大学",
            // "北京理工大学",
            // "北京科技大学",
            // "北京航空航天大学",
            // "北京邮电大学",
            // "常州大学",
            // "成都信息工程大学",
            // "成都师范学院",
            // "重庆医科大学",
            // "重庆邮电大学",
            // "长春理工大学",
            // "东北大学",
            // "东北师范大学",
            // "东华大学",
            // "东南大学",
            // "大连理工大学",
            "电子科技大学",
            // "复旦大学",
            // "福州大学",
            "广州大学",
            // "华中农业大学",
            // "华南农业大学",
            // "华南理工大学",
            // "哈尔滨工业大学",
            // "哈尔滨工程大学",
            // "杭州电子科技大学",
            // "河北工业大学",
            // "河南工学院",
            // "湖北工业大学",
            // "湖北工业大学工程技术学院",
            // "湖北汽车工业学院",
            // "湖北美术学院",
            // "湖南大学",
            // "菏泽学院",
            // "衡阳师范学院",
            // "九江学院",
            // "江苏海洋大学",
            // "江西财经大学",
            // "金陵科技学院",
            // "集美大学诚毅学院",
            // "昆明理工大学",
            // "兰州理工大学",
            // "辽宁对外经贸学院",
            // "辽宁科技学院",
            // "内蒙古科技大学包头医学院",
            // "南京信息工程大学",
            // "南京师范大学",
            // "南开大学",
            // "清华大学",
            // "上海杉达学院",
            // "上海理工大学",
            // "四川师范大学",
            // "四川音乐学院",
            // "山东建筑大学",
            // "山东科技大学",
            // "石河子大学",
            // "苏州大学",
            // "陕西师范大学",
            // "陕西科技大学",
            // "同济大学",
            // "天津大学",
            // "天津理工大学中环信息学院",
            // "武昌理工学院",
            // "武汉工程大学",
            // "武汉理工大学",
            // "温州医科大学",
            // "湘潭大学",
            // "西北工业大学",
            // "西南交通大学",
            // "西南石油大学",
            // "西安交通大学",
            // "西安电子科技大学",
            // "西安科技大学",
            // "西安邮电大学",
            // "云南大学",
            // "宜春学院",
            // "燕山大学",
            // "盐城工学院",
            // "中南大学",
            // "中南民族大学",
            // "中国人民大学",
            // "中国石油大学（华东）",
            // "中国药科大学",
            // "浙大城市学院",
            // "浙江万里学院",
            // "浙江农林大学",
            // "浙江外国语学院",
            // "浙江大学",
            // "浙江工业大学",
          ],
        },

        { name: "A", school: ["安徽中医药大学"] },
        {
          name: "B",
          school: [
            "北京交通大学",
            "北京化工大学",
            "北京工业大学",
            // "北京师范大学",
            // "北京林业大学",
            // "北京理工大学",
            // "北京科技大学",
            // "北京航空航天大学",
            // "北京邮电大学",
          ],
        },
        { name: "D", school: ["电子科技大学"] },
        { name: "G", school: ["广州大学"] },
      ],
      schoolData: [
        "安徽中医药大学",
        "北京交通大学",
        "北京化工大学",
        "北京工业大学",
        "电子科技大学",
        "广州大学",
      ],
      nameAry: [
        {
          name: "全部",
          value: [
            // "包文莉",
            // "包秀娟",
            // "毕盛",
            // "常成",
            // "常虹",
            // "朝乐门",
            // "陈军波",
            // "陈星海",
            // "陈晓希",
            // "陈朋",
            // "陈清奎",
            // "陈立斌",
            // "陈芳",
            // "丁妍",
            // "丁守成",
            // "戴绍港",
            "董妍妍",
            // "邸志雄",
            // "付伟伟",
            // "付明磊",
            // "付长坤",
            // "冯建周",
            "方娟",
            // "范小芳",
            // "辜勇",
            // "高庆华",
            // "高新柱",
            // "何凡",
            "何宾",
            // "何建",
            "何翔",
            // "侯慧",
            // "胡光琴",
            // "胡少勇",
            "胡映东",
            // "胡源",
            // "郝丽",
            // "韩宇星",
            // "纪东升",
            // "贾利军",
            // "刘丹丹",
            // "刘亮",
            // "刘富勤",
            // "刘强",
            // "刘晓强",
            // "刘玉柱",
            // "刘红霞",
            // "刘雅琼",
            // "刘静",
            // "卢宇",
            // "吕发金",
            // "李乐",
            // "李华晶",
            // "李博",
            // "李广培",
            // "李建欣",
            // "李文明",
            // "李然",
            // "李珂",
            // "李磊",
            // "李翰超",
            // "李雁翎",
            // "李鸿志",
            // "林幼平",
            // "罗元",
            // "罗士美",
            // "赖文波",
            // "乜勇",
            // "孟琪",
            // "梅龙宝",
            // "马旭",
            // "马瑞新",
            // "牛卫宁",
            // "牛淑珍",
            // "欧阳建权",
            // "孙霓刚",
            // "宋飞朝",
            // "石兵",
            // "苏菲",
            // "汤峰",
            // "田水承",
            // "谭佐军",
            // "谭浩",
            // "吴明晖",
            // "吴涛",
            // "吴钟鸣",
            "温武",
            // "王刚",
            // "王宜怀、张建",
            // "王文娟",
            // "王新怀",
            // "王树良",
            // "王海若",
            // "王爽",
            // "王超",
            // "王长浩",
            // "王震坡",
            // "魏燕",
            // "徐九韵",
            // "徐科",
            // "熊芸",
            // "原晓楠",
            // "杨佳佳",
            // "杨建磊",
            // "杨文荣",
            // "杨果仁",
            // "杨菁",
            // "袁敏",
            // "闫国庆",
            // "周斌",
            // "张克俊",
            // "张帆",
            // "张彬",
            // "张慧萍",
            // "张晨曦",
            // "张桂力",
            // "张沛",
            // "张艳杰",
            // "战德臣",
            // "朱湘晖",
            // "赵姝颖",
            // "赵晶",
            // "郑莉芳",
            // "钟锐",
          ],
        },
        { name: "D", value: ["董妍妍"] },
        { name: "F", value: ["方娟"] },
        { name: "H", value: ["何滨", "何翔", "胡映东"] },
        { name: "W", value: ["温武"] },
        // {
        //   name: "C",
        //   value: [
        //     "常成",
        //     "常虹",
        //     "朝乐门",
        //     "陈军波",
        //     "陈星海",
        //     "陈晓希",
        //     "陈朋",
        //     "陈清奎",
        //     "陈立斌",
        //     "陈芳",
        //   ],
        // },
      ],
      namData: ["董妍妍", "方娟", "何宾", "何翔", "胡映东", "温武"],
      qyAry: [
        {
          name: "全部",
          value: [
            // "阿里云计算有限公司",
            // "北京世纪超星信息技术发展有限责任公司",
            // "北京中软国际信息技术有限公司",
            // "北京六部工坊科技有限公司",
            // "北京博导前程信息技术股份有限公司",
            // "北京外研讯飞教育科技有限公司",
            // "北京微瑞集智科技有限公司",
            // "北京慕华信息科技有限公司",
            // "北京文华在线教育科技股份有限公司",
            // "北京时代行云科技有限公司",
            // "北京智欣联创科技有限公司",
            // "北京柏慕进业工程咨询有限公司",
            // "北京津发科技股份有限公司",
            "北京海云捷迅科技有限公司",
            // "北京润尼尔网络科技有限公司",
            // "北京炎凌嘉业机电设备有限公司",
            // "北京福道威光仪器技术有限公司",
            // "北京络捷斯特科技发展股份有限公司",
            // "北京绿建软件股份有限公司",
            // "北京聚源锐思数据科技有限公司",
            // "北京象新力科技有限公司",
            // "北京赛佰特科技有限公司",
            // "北京超星尔雅教育科技有限公司",
            // "百度在线网络技术（北京）有限公司",
            // "成都天衡电科科技有限公司",
            // "成都泰盟软件有限公司",
            // "成都芯源系统有限公司",
            // "重庆华教科技有限公司",
            // "大恒新纪元科技股份有限公司",
            // "大连通科应用技术有限公司",
            // "德州仪器半导体技术（上海）有限公司",
            // "戴尔（中国）有限公司",
            "光辉城市（重庆）科技有限公司",
            // "固高派动（东莞）智能科技有限公司",
            // "国际商业机器（中国）有限公司",
            // "广州飞瑞敖电子科技股份有限公司",
            // "广联达科技股份有限公司",
            // "谷歌信息技术（中国）有限公司",
            "华为技术有限公司",
            // "慧科教育科技集团有限公司",
            // "杭州安恒信息技术股份有限公司",
            // "湖南通远网络股份有限公司",
            // "霍尼韦尔Tridium",
            // "机器时代（北京）科技有限公司",
            // "江苏哈工海渡工业机器人有限公司",
            // "开元电子商务（深圳）有限公司",
            // "开来科技（深圳）有限公司",
            // "美林数据技术股份有限公司",
            // "南京世格软件有限责任公司",
            // "南京奥派信息产业股份公司",
            // "南京朝享者网络科技有限公司",
            // "南京研旭电气科技有限公司",
            // "苹果电脑贸易（上海）有限公司",
            "上海卓越睿新数码科技股份有限公司",
            // "上海和盛前沿科技有限公司",
            // "上海巧园投资发展有限公司",
            // "上海恩艾仪器有限公司",
            // "上海曼恒数字技术股份有限公司",
            // "世纪文都教育科技集团股份有限公司",
            // "四川升拓检测技术股份有限公司",
            "四川华迪信息技术有限公司",
            // "山东浪潮铸远教育科技有限公司",
            // "深圳市斯维尔科技股份有限公司",
            // "深圳市腾讯计算机系统有限公司",
            // "深圳市鼎阳科技股份有限公司",
            // "武汉光驰教育科技股份有限公司",
            // "武汉噢易云计算股份有限公司",
            // "先临三维科技股份有限公司",
            "新华三技术有限公司",
            // "西安三好软件技术股份有限公司",
            // "意法半导体（中国）投资有限公司",
            // "浙江求是科教设备有限公司",
          ],
        },
        { name: "B", value: ["北京海云捷迅科技有限公司"] },
        { name: "G", value: ["光辉城市（重庆）科技有限公司"] },
        { name: "H", value: ["华为技术有限公司"] },
        {
          name: "S",
          value: [
            "上海卓越睿新数码科技股份有限公司",
            "四川华迪信息技术有限公司",
          ],
        },
        { name: "X", value: ["新华三技术有限公司"] },
      ],
      qyData: [
        "北京海云捷迅科技有限公司",
        "光辉城市（重庆）科技有限公司",
        "华为技术有限公司",
        "上海卓越睿新数码科技股份有限公司",
        "四川华迪信息技术有限公司",
        "新华三技术有限公司",
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init(
      type = "全部", // 项目类型
      num = "全部", // 项目批次
      schoolD = "", //高校名称
      nameD = "", // 企业名称
      jobD = "", //项目负责人
      searchD = "" // 搜索
    ) {
      debugger;
      if (
        type == "全部" &&
        num == "全部" &&
        schoolD == "" &&
        nameD == "" &&
        jobD == "" &&
        searchD == ""
      ) {
        this.cardData = [];
        for (let i = 0; i < this.cardAry.length; i++) {
          this.cardData.push(this.cardAry[i]);
        }
      } else if (schoolD != "") {
        this.cardData = [];
        for (let i = 0; i < this.cardAry.length; i++) {
          if (this.cardAry[i].list[2].value == schoolD) {
            this.cardData.push(this.cardAry[i]);
          }
        }
      } else if (nameD != "") {
        this.cardData = [];
        for (let i = 0; i < this.cardAry.length; i++) {
          if (this.cardAry[i].list[3].value == nameD) {
            this.cardData.push(this.cardAry[i]);
          }
        }
      } else if (jobD != "") {
        this.cardData = [];
        for (let i = 0; i < this.cardAry.length; i++) {
          if (this.cardAry[i].list[4].value == jobD) {
            this.cardData.push(this.cardAry[i]);
          }
        }
      } else if (searchD != "") {
        debugger
        this.cardData = [];
        for (let i = 0; i < this.cardAry.length; i++) {
          for (let y = 0; y < this.cardAry[i].list.length; y++) {
            if (this.cardAry[i].list[y].value == searchD) {
              this.cardData.push(this.cardAry[i]);
            }
          }
        }
      } else if (type != "全部" && num != "全部") {
        this.cardData = [];
        for (let i = 0; i < this.cardAry.length; i++) {
          if (
            this.cardAry[i].list[0].value == type &&
            this.cardAry[i].list[1].value == num
          ) {
            this.cardData.push(this.cardAry[i]);
          }
        }
      } else if (type != "全部") {
        this.cardData = [];
        for (let i = 0; i < this.cardAry.length; i++) {
          if (this.cardAry[i].list[0].value == type) {
            this.cardData.push(this.cardAry[i]);
          }
        }
      } else if (num != "全部") {
        debugger;
        this.cardData = [];
        for (let i = 0; i < this.cardAry.length; i++) {
          if (this.cardAry[i].list[1].value == num) {
            this.cardData.push(this.cardAry[i]);
          }
        }
      }
    },
    go() {
      this.$emit("indexFun", 1);
    },
    tyepFun(index, title) {
      this.styleType = index;
      this.type = title;
      this.schoolD == "";
      this.nameD == "";
      this.jobD == "";
      this.searchD == "";
      this.tagsV = false;
      this.tags = {}
      this.init(title, this.num);

    },
    numFun(index, title) {
      this.num = title;
      this.styleNum = index;
      this.school == "";
      this.name == "";
      this.job == "";
      this.searchD == "";
      this.tagsV = false;
      this.tags = {}
      this.init(this.type, title);
      // 项目类型
    },
    schoolTitleFun(index, item) {
      debugger;
      this.styleSchool = index;
      if (item == "全部") {
        this.schoolData = [
          "安徽中医药大学",
          "北京交通大学",
          "北京化工大学",
          "北京工业大学",
          "电子科技大学",
          "广州大学",
        ];
      } else if (item == "A") {
        this.schoolData = ["安徽中医药大学"];
      } else if (item == "B") {
        this.schoolData = ["北京交通大学", "北京化工大学", "北京工业大学"];
      } else if (item == "D") {
        this.schoolData = ["电子科技大学"];
      } else if (item == "G") {
        this.schoolData = ["广州大学"];
      }
    },
    schoolFun(title) {
      this.styleType = "0"; // 项目类型
      this.styleNum = "0"; // 项目批次
      //    this.schoolD == "" ;
      //     this.name == "" ;
      //     this.job == "" ;
      //    this.searchD == "";
      this.init(this.type, this.num, title);
      this.tags = { name:title };
      this.tagsV = true
    },
    schoolJobFun(index, item) {
      debugger;
      this.styleJob = index;
      if (item == "全部") {
        this.namData = ["董妍妍", "方娟", "何宾", "何翔", "胡映东", "温武"];
      } else if (item == "D") {
        this.namData = ["董妍妍"];
      } else if (item == "F") {
        this.namData = ["方娟"];
      } else if (item == "H") {
        this.namData = ["何宾", "何翔", "胡映东"];
      } else if (item == "W") {
        this.namData = ["温武"];
      }
    },
    schoolNameFun(index, item) {
      this.styleName = index;
      if (item == "全部") {
        this.qyData = [
          "北京海云捷迅科技有限公司",
          "光辉城市（重庆）科技有限公司",
          "华为技术有限公司",
          "上海卓越睿新数码科技股份有限公司",
          "四川华迪信息技术有限公司",
          "新华三技术有限公司",
        ];
      } else if (item == "B") {
        this.qyData = ["北京海云捷迅科技有限公司"];
      } else if (item == "G") {
        this.qyData = ["光辉城市（重庆）科技有限公司"];
      } else if (item == "H") {
        this.qyData = ["华为技术有限公司"];
      } else if (item == "S") {
        this.qyData = [
          "上海卓越睿新数码科技股份有限公司",
          "四川华迪信息技术有限公司",
        ];
      } else if (item == "X") {
        this.qyData = ["新华三技术有限公司"];
      }
    },
    nameFun(title) {
      console.log(title);
      this.styleType = "0"; // 项目类型
      this.styleNum = "0"; // 项目批次
      this.searchD = ''
      this.init(this.type, this.num, "", title);
      this.tags = { name:title };
      this.tagsV = true
    },
    jobFun(title) {
      console.log(title);
      this.styleType = "0"; // 项目类型
      this.styleNum = "0"; // 项目批次
      this.searchD = ''
      this.init(this.type, this.num, "", "", title);
      this.tags = { name:title };
      this.tagsV = true
    },

    searchFun(val) {
    
      this.styleType = "0"; // 项目类型
      this.styleNum = "0"; // 项目批次
     
      this.init(this.type, this.num, "", "", "", this.searchD);
      this.tags = { name:this.searchD };
      this.tagsV = true
    },
    closeFun() {
      this.tags = {};
      this.tagsV = false;
      this.init();
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
.projects {
  margin-top: 20px;
  text-align: left;

  .box {
    margin-top: 30px;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    .title {
      font-weight: bold;
    }
    .list {
      font-size: 14px;
      color: #858585;
      overflow: hidden;
      // display: flex;
      // justify-content: left;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px dashed #ccc;

      .hover {
        cursor: pointer;
        &:hover {
          color: #6baff9;
        }
      }
      .ul {
        margin-left: 100px !important;
        max-height: 200px;
        overflow: auto;
        .li {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 5px;
        }
      }
      .min-hei {
        max-height: 220px;
        overflow: auto;

        li {
          margin-left: 30px;
        }
      }
      span {
        float: left;
        width: 90px;
      }
      ul {
        margin-left: 90px;
        overflow: hidden;
        li {
          float: left;
          margin-left: 20px;
        }
      }
    }
    .list-min {
      height: 150px;
    }
    .list-bor {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  .box_card {
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
    .title {
      font-size: 14px;
      color: #858585;
      font-weight: bold;
    }
    .body_title {
      color: #3498db;
      background-color: #d9edf7;
      border-color: #bce8f1;
      height: 65px;
      padding: 10px 15px;
      font-weight: bold;
      box-sizing: border-box;
      border-bottom: 1px solid transparent;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
    }
  }
  .body_list {
    padding: 3px 10px;
    font-size: 14px;
  }
}

.el-divider--horizontal {
  margin: 12px 0;
}
// .el-card /deep/ .el-card__body{
//         padding:0;
//         background:red;
//       }
.el-card ::v-deep .el-card__body {
  padding: 0;
}
.addBor {
  // border:1px solid #6baff9;
  color: #6baff9;
  // p // border:1px solid #6baff9;
}
.add-bor {
  border: 1px solid #6baff9;
  padding: 0 2px;
}
.bor {
  border: 1px solid #fff;
  padding: 0 2px;
}
.el-card
{height:222px;}
@media (max-width: 1000px) {
    .box_card .el-col-8{
        width:100%;
    }
}
</style>